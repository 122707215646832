import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { QUERY_KEYS, IKeyLevelPayload } from 'utilities';
import { useHandleError, useModalAlert } from 'hooks';
import { useReloadUser } from '../../hooks/useLoadUser';
import { kycLevelTwoUpdate } from 'api';
import TextInput from '../form-components/text-input';
import Button from '../button';

type Props = {
	callback?: () => void;
};

const IdentityVerificationForm = ({ callback }: Props) => {
	const theme = useTheme();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const modal = useModalAlert();
	const queryClient = useQueryClient();
	const reloadUser = useReloadUser();

	const initialValues: Partial<IKeyLevelPayload> = {
		payload: '',
		type: 'NIN',
		lastname: '',
		firstname: '',
		phone: '',
	};

	const validationSchema = yup.object().shape({
		payload: yup
			.string()
			.matches(/\d{11}/, 'NIN must be a 11 digit nummber')
			.required('Enter NIN'),
		firstname: yup.string().required('Enter firstname'),
		lastname: yup.string().required('Enter lastname'),
		phone: yup.string().required('Enter phone number'),
	});

	const { isLoading, mutate } = useMutation(kycLevelTwoUpdate, {
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res && res.message) {
					const regExp = new RegExp('PAYLOAD exists', 'ig');
					const message = res.message;

					const responseMsg = regExp.test(message)
						? 'NIN has already been used.'
						: message;

					modal({
						type: 'error',
						title: 'Identity verification',
						message: responseMsg,
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
			if (data && data.success) {
				resetForm();
				// queryClient.invalidateQueries(QUERY_KEYS.Me);
				reloadUser();
				modal({
					type: 'success',
					title: 'Identify Verification',
					message: data.message,
					primaryButtonText: 'Continue',
					onClickPrimaryButton: () => {
						modal(null);
						queryClient.invalidateQueries(QUERY_KEYS.Me);
						typeof callback === 'function' && callback();
					},
				});
			}
		},
	});

	const { touched, values, errors, handleSubmit, handleChange, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values) => {
				mutate(values);
			},
		});

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography variant={'body1'}>
					Please type in your National Identification Number (NIN) along side
					the rest of your details to verify your identity.
				</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				<TextInput
					fullWidth
					labelText={'NIN'}
					placeholder={'Enter NIN Number'}
					error={errors && touched.payload && errors.payload ? true : false}
					helperText={errors && touched.payload && errors.payload}
					value={values.payload}
					onChange={handleChange('payload')}
				/>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: {
							xs: '1fr',
							md: 'repeat(2, 1fr)',
						},
						gap: (theme) => theme.spacing(3),
					}}
				>
					<TextInput
						fullWidth
						labelText={'First name'}
						placeholder={'Enter your first name'}
						error={
							errors && touched.firstname && errors.firstname ? true : false
						}
						helperText={errors && touched.firstname && errors.firstname}
						value={values.firstname}
						onChange={handleChange('firstname')}
					/>
					<TextInput
						fullWidth
						labelText={'Last name'}
						placeholder={'Enter your last name'}
						error={errors && touched.lastname && errors.lastname ? true : false}
						helperText={errors && touched.lastname && errors.lastname}
						value={values.lastname}
						onChange={handleChange('lastname')}
					/>
				</Box>
				<TextInput
					fullWidth
					labelText={'Phone number'}
					error={errors && touched.phone && errors.phone ? true : false}
					helperText={errors && touched.phone && errors.phone}
					placeholder={'Enter  your Phone number'}
					value={values.phone}
					onChange={handleChange('phone')}
				/>

				<Box>
					<Button
						fullWidth
						style={styles.btn}
						size={'large'}
						loading={isLoading}
						onClick={(e: React.FormEvent<HTMLButtonElement>) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						Submit
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontWeight: '500',
	},
	emailText: {
		fontWeight: '600',
	},
});

export default IdentityVerificationForm;
