import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button/custom-button';
import Select from '../form-components/select';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import {
	QUERY_KEYS,
	calculatePercentageAmount,
	INetwork,
	AirtimeConversion,
	formatNumberToCurrency,
	LINKS,
	SECOUNDARY_COLOR,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import {
	useHandleError,
	useModalAlert,
	useVerifyPin,
	useAlert,
	useCheckKycLevelTwo,
} from 'hooks';
import { airtimeConvertNetworks, autoConvertAirtime } from 'api';
import CustomPlaceholder from '../partials/custom-text-input-holder';

interface IAirtimeConversion extends AirtimeConversion {
	sessionId: string;
	noOfRetries: string;
}

const SELECT_NUMBER_OF_SHARE = 'Select number of share';

const InstantConvertAirtimeForm = () => {
	const alert = useAlert();
	const navigate = useNavigate();
	const theme = useTheme();
	const styles = useStyles(theme);
	const verifyPin = useVerifyPin();
	const modal = useModalAlert();
	const checkKycLevel = useCheckKycLevelTwo();
	const handleError = useHandleError();
	const { state: urlState, search } = useLocation();
	const parsedQuery = queryString.parse(search);
	const { token } = useAppSelector((store) => store.auth);
	const queryClient = useQueryClient();
	const parseQueryString: any = urlState;

	const phone_number = parsedQuery?.phone || urlState?.phone;
	const network = parsedQuery?.network || urlState?.network;
	const networkName = urlState?.networkName;
	const sessionId = parsedQuery?.sessionId || urlState?.sessionId;
	const airtimeBalance =
		parsedQuery?.airtimeBalance || urlState?.airtimeBalance;
	const rate = parsedQuery?.rate || urlState?.rate;

	const isAirtelNetwork = new RegExp('airtel', 'ig').test(networkName);

	const initialValues: IAirtimeConversion = {
		amount: '',
		phone_number,
		network,
		sessionId,
		pin: '',
		noOfRetries: isAirtelNetwork ? '1' : SELECT_NUMBER_OF_SHARE,
	};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.lessThan(
				isAirtelNetwork ? 20001 : 5001,
				`Amount cannot be more than ${formatNumberToCurrency(5000)}`
			)
			.moreThan(
				 499,
				`Amount cannot be less than ${formatNumberToCurrency(500)}`
			)
			.positive('Amount must be positive digit')
			.required('Amount is required'),
		pin: yup.string().required('Enter sell and share  pin'),
		noOfRetries: yup.number().required('Select number of share'),
	});

	const { data: dataNetworkProviders } = useQuery(
		QUERY_KEYS.GetConvertNetwork,
		() => airtimeConvertNetworks({ isActive: true }),
		{
			enabled: !!token,
			retry: 1,
			refetchOnWindowFocus: false,
		}
	);

	const { isLoading, mutate: mutateConvertAirtime } = useMutation(
		autoConvertAirtime,
		{
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							type: 'error',
							title: 'Convert Airtime to Cash',
							message: response.message,
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);

					modal({
						type: 'success',
						title: 'Auto Airtime to Cash',
						message: `Auto airtime conversion is successful!`,
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							navigate(LINKS.RequestConvertAirtimeOtp);
							modal(null);
						},
					});
				}
			},
		}
	);

	const {
		handleSubmit,
		handleChange,
		errors,
		touched,
		values,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			if (!phone_number && !network && !sessionId)
				return alert({
					message: 'Unable to perform operation, contact support',
					type: 'error',
				});

			// Check account balance with number of share
			const simAirtimeBalance = `${airtimeBalance}`
				?.replace(/,/g, '')
				.split('₦')[1];

			if (
				parseFloat(simAirtimeBalance) <
				parseFloat(`${values.amount}`) * Number(values.noOfRetries)
			) {
				modal({
					type: 'error',
					title: 'Insufficient Balance',
					message:
						'Unable to process transaction, ensure you have sufficient airtime amount.',
					primaryButtonText: 'Close',
					onClickPrimaryButton: () => modal(null),
				});

				return;
			}

			const handleConvertAirtime = () => {
				verifyPin(null);
				mutateConvertAirtime({ ...values, pin: values.pin?.toString() });
			};

			if (checkKycLevel()) {
				verifyPin({
					title: 'Convert Airtime',
					message: `Verify transaction pin to convert airtime`,
					callback: handleConvertAirtime,
				});
			}
		},
	});

	const { amount, pin, noOfRetries } = values;

	// Set values from url
	useEffect(
		() => {
			if (parseQueryString && parseQueryString.network) {
				const _network =
					dataNetworkProviders &&
					dataNetworkProviders.payload.find(
						(network: INetwork) => network.id === parseQueryString.network
					);

				if (_network) {
					setFieldValue('network', _network.id);
					const cal_amount = Math.round(
						calculatePercentageAmount({
							amount: Number(amount),
							rate: _network.rate as string,
						})
					);
				}
			}
		},
		// eslint-disable-next-line
		[dataNetworkProviders]
	);

	return (
		<>
			<Box
				onSubmit={(e: any) => {
					e.preventDefault();
					handleSubmit();
				}}
				style={styles.form as any}
				component={'form'}
			>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Amount to sale
						</Typography>
						<Typography component={'label'} variant={'body1'}>
							Airtime Balance:{' '}
							<span
								style={{
									color: SECOUNDARY_COLOR,
									fontWeight: '600',
								}}
							>
								{airtimeBalance}
							</span>
						</Typography>
					</Box>

					<TextInput
						fullWidth
						type={'number'}
						error={touched.amount && Boolean(errors.amount)}
						helperText={touched.amount && errors.amount}
						placeholder={'Type in amount of airtime for sale'}
						value={amount}
						onChange={handleChange('amount')}
					/>
				</Box>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Amount to receive
						</Typography>
						{rate && (
							<Typography component={'label'} variant={'body1'}>
								Rate:{' '}
								<span
									style={{
										color: SECOUNDARY_COLOR,
										fontWeight: '600',
									}}
								>
									{rate}%
								</span>
							</Typography>
						)}
					</Box>
					<CustomPlaceholder
						text={
							amount && rate
								? calculatePercentageAmount({
										rate,
										amount: `${amount}`,
								  }).toString()
								: 'Amount to receive'
						}
					/>
				</Box>

				{!isAirtelNetwork && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Number of share
						</Typography>
						<Select
							error={touched.noOfRetries && errors.noOfRetries ? true : false}
							helpertext={touched.noOfRetries && errors.noOfRetries}
							fullWidth
							value={noOfRetries}
							onChange={(e: any) =>
								setFieldValue('noOfRetries', e.target.value)
							}
						>
							<MenuItem value={SELECT_NUMBER_OF_SHARE} disabled>
								Select number of share
							</MenuItem>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
								<MenuItem value={value} key={value}>
									{value}
								</MenuItem>
							))}
						</Select>
					</Box>
				)}

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Sell and Share pin
					</Typography>

					<TextInput
						fullWidth
						type={'number'}
						error={Boolean(touched.pin && errors.pin)}
						helperText={touched.pin && errors.pin}
						placeholder={'Type in sell and share pin'}
						value={pin}
						onChange={handleChange('pin')}
					/>
				</Box>

				<Button
					loading={isLoading}
					buttonProps={{
						type: 'submit',
						size: 'large',
						style: styles.btn,
					}}
				>
					Proceed
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default InstantConvertAirtimeForm;
